import { StatusBar } from 'expo-status-bar';
import React,{useState,useEffect } from 'react';
import { Text, View,ScrollView,Platform } from 'react-native';
import { Provider } from "react-redux";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './screens/HomeScreen';
import DishScreen from './screens/DishScreen';
import { store } from './store';
import BasketScreen from './screens/BasketScreen';
import PreparingOrderScreen from './screens/PreparingOrderScreen';
import OrderScreen from './screens/OrderScreen';
import { Router, Route } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./styles.css";
import RestaurantScreen from './screens/RestaurantScreen';
import { Analytics } from "@vercel/analytics/react";
import AsyncStorage from '@react-native-async-storage/async-storage';

const Stack = createNativeStackNavigator();

const PERSISTENCE_KEY = 'NAVIGATION_STATE';


const linking = {
  prefixes: ['qflow://'], // Replace with your app's deep link prefix
  config: {
    screens: {
      Home: 'home',
      Restaurant: 'restaurant/:id?',
      Dish: 'dish/:id',
      Basket: 'basket',      
      Order: 'order/:orderId'
      // PreparingOrder: 'preparing-order',
    },
  },
};


export default function App() {
  const [isReady, setIsReady] = useState(false);
  const [initialState, setInitialState] = useState();

  useEffect(() => {
    const restoreState = async () => {
      try {
        await AsyncStorage.removeItem(PERSISTENCE_KEY);
        const savedState = await AsyncStorage.getItem(PERSISTENCE_KEY);
        const state = savedState ? JSON.parse(savedState) : undefined;
        setInitialState(state);
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

    return (
      <View style={{ flex: 1 }} >
      <NavigationContainer  initialState={undefined}
      onStateChange={(state) =>
        AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state))
      }
 linking={linking}>
      <ToastContainer />
      <Provider store={store}>
        <Stack.Navigator>
          <Stack.Screen name="Home" component={HomeScreen}/>
          <Stack.Screen name="Dish" component={DishScreen} />
          <Stack.Screen name="Restaurant" component={RestaurantScreen} />
          <Stack.Screen 
          name="Basket" 
          component={BasketScreen} 
            options={{ presentation: 'modal', headerShown: false }} 
  
            />
            <Stack.Screen 
            name="PreparingOrderScreen" 
            component={PreparingOrderScreen} 
            options={{ presentation: 'fullScreenModal', headerShown: false }}
            />
             <Stack.Screen 
            name="Order" 
            component={OrderScreen} 
            options={{ presentation: 'fullScreenModal', headerShown: false }}
            />
        </Stack.Navigator>
      </Provider>
      </NavigationContainer>
      <Analytics/>
      </View>
      
    ); 
}
