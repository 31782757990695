import { View, Text, TouchableOpacity, Image } from "react-native";
import React from "react";
import { useNavigation } from "@react-navigation/native";

const MenuItemCard = ({
  id,
  imgSrc,
  title,
  description,
  basePrice
}) => {
  const navigation = useNavigation();

  const navigateToDish = ()=>{
    console.log('Nav',navigation);
    navigation.navigate("Dish", { id })
  }
  return (
    <TouchableOpacity
      onPress={navigateToDish}
      style={{ flexDirection: 'column', margin: 15, backgroundColor: 'white', alignItems: 'center' }}
    >
      {/* Horizontal line above content */}
      <View style={{ width: '100%', height: 1, backgroundColor: 'black'}} />

      {/* Content Container */}
      <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center', paddingTop:15 }}>
        {/* Image Column */}
        <Image
          source={{ uri: imgSrc }}
          style={{ width: 100, height: 100, marginRight: 10 }}
          resizeMode="cover"
        />

        {/* Text Column */}
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 18, fontWeight: 'bold' }}>{title}</Text>
          <Text style={{ fontSize: 14, color: 'gray' }}>{description}</Text>
          {basePrice && basePrice !== 0 ? <Text style={{ fontSize: 16, fontWeight: 'bold', color: 'gray' }}>
            R {basePrice}
          </Text>:
          <Text style={{ fontSize: 16, fontWeight: 'bold', color: 'gray' }}>
          Price varies
        </Text>}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default MenuItemCard;
