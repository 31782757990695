import { useState } from 'react';
import styled from 'styled-components';
import DayTimePicker from '@mooncake-dev/react-day-time-picker';

const theme = {
    primary: 'black',
    secondary: 'slategrey',
    background: '#ffffff', // This should match the container background
    buttons: {
      disabled: {
        color: '#ff0000',
        background: '#ff0000'
      },
      confirm: {
        color: '#fff',
        background: 'slategrey',
        hover: {
          color: '',
          background: 'lightslategrey'
        }
      }
    },
    feedback: {
      success: {
        color: '#29aba4'
      },
      failed: {
        color: '#eb7260'
      }
    }
  };
  const bookings = {
    "2024-06-10": [
      { hours: 12, minutes: 0 },
      { hours: 13, minutes: 30 },
      { hours: 14, minutes: 30 },
      { hours: 14, minutes: 0 }
    ],
    "2024-06-15": [
      { hours: 9, minutes: 0 },
      { hours: 14, minutes: 30 }
    ],
    "2024-06-11": [
      { hours: 9, minutes: 0 },
      { hours: 14, minutes: 0 },
      { hours: 15, minutes: 30 },
      { hours: 16, minutes: 0 }
    ]
  };

const bookedTimes = {
  "2024-06-10": [
    { hours: 12, minutes: 0 },
    { hours: 13, minutes: 30 },
    { hours: 14, minutes: 30 },
    { hours: 14, minutes: 0 }
  ],
  "2024-06-15": [
    { hours: 9, minutes: 0 },
    { hours: 14, minutes: 30 }
  ],
  "2024-06-11": [
    { hours: 9, minutes: 0 },
    { hours: 14, minutes: 0 },
    { hours: 15, minutes: 30 },
    { hours: 16, minutes: 0 }
  ]
};
const DarkContainer = styled.div`
  width: 475px;
  margin: 1em auto;
  padding: 1em;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  text-align: center;
  @media (max-width: 520px) {
    width: 100%;
  }
`;

function timeSlotValidator(slotTime) {
  const startTime = new Date(
    slotTime.getFullYear(),
    slotTime.getMonth(),
    slotTime.getDate(),
    7,
    0,
    0
  );
  const endTime = new Date(
    slotTime.getFullYear(),
    slotTime.getMonth(),
    slotTime.getDate(),
    21,
    0,
    0
  );

  const dateKey = slotTime.toISOString().split('T')[0];
  const isWithinTimeRange = slotTime.getTime() >= startTime.getTime() && slotTime.getTime() <= endTime.getTime();

  if (isWithinTimeRange) {
    if (bookedTimes[dateKey]) {
      return !bookedTimes[dateKey].some(
        bookedTime =>
          bookedTime.hours === slotTime.getHours() &&
          bookedTime.minutes === slotTime.getMinutes()
      );
    }
    return true;
  }

  return false;
}

export default function Picker() {
  return (
    <DarkContainer>
    <div className='px-12 py-2 shadow-xl border border-black rounded-xl justify-items-center'>
      <DayTimePicker
        className="justify-items-center p-10"
        timeSlotSizeMinutes={30}
        timeSlotValidator={timeSlotValidator}
        theme={theme}
        isLoading={true}
        isDone={true}
        onConfirm={()=>{}}
      />
    </div>
    </DarkContainer>
    );
}