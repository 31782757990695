import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  Image,
  ScrollView,
  TextInput,
  StyleSheet,
} from "react-native";
import React, { useState, useEffect } from "react";
import { useNavigation } from "@react-navigation/native";
import { useSelector, useDispatch } from "react-redux";
import { selectFavorite } from "../features/restaurantSlice";
import { selectBasketItems, selectBasketTotal } from "../features/basketSlice";
import { XCircleIcon, TrashIcon } from "react-native-heroicons/outline";
import Currency from "react-currency-formatter";
import { removeFromBasket, clearBasket } from "../features/basketSlice";
import axios from "axios";
import { environment } from "../environment/environment";
import Collapsible from "react-native-collapsible";
import CouponComponent from "../components/CouponComponent";
import "../styles.css";
import { Input, TextField } from "@mui/material";

const BasketScreen = () => {
  const navigation = useNavigation();
  const favorite = useSelector(selectFavorite);
  const items = useSelector(selectBasketItems);
  const basketTotal = useSelector(selectBasketTotal);
  const [groupedItemsInBasket, setGroupedItemsInBasket] = useState([]);
  const [publicKey, setPublicKey] = useState("");
  const [orderItems, setOrderItems] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [tipOption, setTipOption] = useState({
    optionKey: "No Tip",
    optionValue: 0,
  });
  const [tipValue, setTipValue] = useState(0.0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCouponCollapsed, setIsCouponCollapsed] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [restaurantId, setRestaurantId] = useState("");
  const [discountUsed, setDiscountUsed] = useState("");
  const [itemsExluded, setItemsExcluded] = useState([""]);
  const [serviceCharge, setServiceCharge] = useState(35);
  const vatPercentage = 0.15;

  const applyCoupon = (discountContent) => {
    let discountAmount = 0;
    let totalForDiscount = 0;

    const itemsToExclude = discountContent.content.itemsToExclude;
    setItemsExcluded(itemsToExclude);
    if (itemsToExclude.length > 0) {
      for (let index = 0; index < items.length; index++) {
        const basketItem = items[index];
        const isExlcudedItem =
          itemsToExclude.filter((i) => i === basketItem.id).length > 0;
        if (!isExlcudedItem) {
          totalForDiscount = totalForDiscount + basketItem.price;
        }
      }
    }

    if (discountContent.content.type === "%") {
      discountAmount = totalForDiscount * (discountContent.content.value / 100);
    } else if (discountContent.content.type === "Rand") {
      discountAmount = discountContent.content.value;
    }

    setDiscount(discountAmount);
    setDiscountUsed(discountContent.name);
  };

  const orderTotal = (basketTotal  + tipValue - discount);
  const vatValue = orderTotal*vatPercentage;

  const [options] = useState([
    { optionKey: "No Tip", optionValue: 0 },
    { optionKey: "5%", optionValue: 5 },
    { optionKey: "10%", optionValue: 10 },
    { optionKey: "15%", optionValue: 15 },
  ]);
  const [timeOption, setTimeOption] = useState({
    optionKey: "For Now",
    optionValue: 0,
  });

  const [timeOptions] = useState([
    { optionKey: "For Now", optionValue: 0 },
    { optionKey: "For Later", optionValue: 1 },
  ]);
  const [timeValue, setTimeValue] = useState("Now");

  useEffect(() => {
    console.log("Items", items);
    if (items.length > 0) {
      const restaurantId = items[0].restuarantId;
      setRestaurantId(restaurantId);
      axios
        .get(`${environment.apiUrl}RestaurantManagement/getpublickey`, {
          params: {
            restaurantId: restaurantId,
          },
        })
        .then((response) => {
          console.log("response.data......");
          console.log(response.data);
          setPublicKey(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [publicKey]);

  const dispatch = useDispatch();
  //   const savedBasket = JSON.parse(localStorage.getItem('basket'));
  //   const yoco = new window.YocoSDK({
  //     publicKey: 'pk_live_b1eef3d0vnnmY9g6bfb4',
  //   });

  //   <Script src="https://js.yoco.com/sdk/v1/yoco-sdk-web.js" strategy="beforeInteractive" />
  const handleYocoPayment = async (event) => {
    event.preventDefault();

    // var basketTotal = dispatch(selectBasketTotal());

    closeModal();
    // setShowCart(false);

    const amountInCents = Math.round(orderTotal * 100);

    console.log(groupedItemsInBasket);

    const yoco = new window.YocoSDK({
      publicKey: "pk_test_111b38dem52gZNPa29b4",
    });
    console.log("BasketItem", items);
    yoco.showPopup({
      amountInCents: amountInCents,
      currency: "ZAR",
      name: "QFlow",
      description: "Des",
      callback: async function (result) {
        if (result.error) {
          const errorMessage = result.error.message;
          //setShowCart(true);
          // toast.error(
          //   "Error occurred: " + errorMessage + ". Please try again."
          // );
        } else {
          try {
            var now = new Date();

            // Convert to ISO string and remove 'Z' (UTC timezone indicator)
            var formattedDate = now.toISOString().replace("Z", "");
            const payload = {
              token: result.id,
              basketItems: items,
              customerName: customerName,
              orderNotes: orderNotes,
              roomNumber: "",
              tip: tipValue,
              discountValue: discount,
              discountUsed: discountUsed,
              roomNumber: "",
              orderCollectTime: timeValue,
              amountInCents: (orderTotal * 100).toFixed(),
              currency: "ZAR",
              basketId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
              orderDate: formattedDate,
            };

            axios
              .post(
                `${environment.apiUrl}OrderManagement/processorder`,
                JSON.stringify(payload),
                {
                  headers: {
                    Accept: "text/plain",
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                if (response) {
                  console.log(response);
                  for (
                    let index = 0;
                    index < response.data.orderContent.basketItems.length;
                    index++
                  ) {
                    const element =
                      response.data.orderContent.basketItems[index];
                    dispatch(removeFromBasket({ id: element.basketItemId }));
                  }
                  const orderId = response.data.orderId;
                  navigation.navigate("Order", { orderId });
                  // try {
                  //   const items = cartItems.map(
                  //     (item, index) => `${item.name} (Quantity: ${item.quantity})`
                  //   );
                  //   //To Client
                  //   sendConfirmationEmail(email, items, payload.shippingInfo);

                  //   // await client.create(order);
                  //   console.log(
                  //     `Order was created, document ID is ${res._id}, order number is ${order.orderNumber}`
                  //   );
                  // } catch (err) {
                  //   console.log("Error creating order:", err.message);
                  // }
                }
              })
              .catch((error) => {
                console.error(error);
              });

            // clearCart();
            // toast.success("Payment successful!");
            // toast.success(
            //   "A copy of your order has been emailed to you. Thank you for the support!"
            // );
          } catch (error) {
            console.log(error);
            //setShowCart(true);
            // toast.error(
            //   "Error occurred: " + error.message + ". Please try again."
            // );
          }
        }
      },
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.YocoSDK) {
      const yoco = new window.YocoSDK({
        publicKey: "pk_test_111b38dem52gZNPa29b4",
      });

      const handleYocoPayment = async () => {
        openModal();
      };

      const checkoutButton = document.querySelector("#checkout-button");
      if (checkoutButton) {
        checkoutButton.addEventListener("click", handleYocoPayment);
      }

      return () => {
        if (checkoutButton) {
          checkoutButton.removeEventListener("click", handleYocoPayment);
        }
      };
    }
  }, []);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const setTip = (selectedTipOption) => {
    setTipOption(selectedTipOption);
    setTipValue(
      ((basketTotal * 100).toFixed() * selectedTipOption.optionValue) /
        100 /
        100
    );
  };

  const setTimeOptionValue = (selectedTimeOption) => {
    setTimeOption(selectedTimeOption);
    setTimeValue("Now");
  };

  const setTimeValueFromInput = (timeSelected) => {
    setTimeValue(timeSelected.target.value);
  };

  useEffect(() => {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://js.yoco.com/sdk/v1/yoco-sdk-web.js";
    s.strategy = "beforeInteractive";
    document.body.appendChild(s);
    const groupedItems = items.reduce((results, item) => {
      (results[item.id] = results[item.id] || []).push(item);
      return results;
    }, {});

    setGroupedItemsInBasket(groupedItems);
    setOrderItems(items);
    console.log(items);
  }, [items]);

  return (
    <SafeAreaView className="flex-1 bg-white">
      <View className="flex-1 bg-gray-100">
        <View className="p-5 border-b border-[#E75300] bg-white shadow-xs">
          <View>
            <Text className="text-lg font-bold text-center">Basket</Text>
            <Text className="text-center text-gray-400">QFlow</Text>
          </View>

          <TouchableOpacity
            onPress={navigation.goBack}
            className="rounded-full bg-gray-100 absolute top-3 right-5"
          >
            <XCircleIcon color="#E75300" height={35} width={35} />
          </TouchableOpacity>
        </View>

        <ScrollView className="divide-y divide-gray-200">
          {orderItems?.map((orderItem) => (
            <View
              key={orderItem.basketItemId}
              className="bg-white mr-3 shadow mt-2 ml-10"
              style={{ marginLeft: 10 }}
            >
              <TouchableOpacity
                onPress={() =>
                  dispatch(removeFromBasket({ id: orderItem.basketItemId }))
                }
                className="rounded-full px-3 pt-2"
              >
                <TrashIcon color="#E75300" height={20} width={20} />
              </TouchableOpacity>
              <View className="px-3 pb-4">
                <View>
                  <View className="flex-row items-center space-x-1">
                    <Text className="flex-1">
                      <Text className="font-bold text-lg pt-2">
                        {orderItem?.name}
                      </Text>
                      {itemsExluded.filter((i) => i === orderItem.id).length >
                      0 ? (
                        <Text className="text-gray-500 text-xs pt-2">
                          - Not in promo
                        </Text>
                      ) : (
                        ""
                      )}
                    </Text>
                    <Text className="font-bold text-lg pt-2">
                      R{orderItem.price}
                    </Text>
                  </View>
                </View>
                {orderItem.selectedOptions.map((selectedOption) => (
                  <View key={selectedOption.id}>
                    <View className="flex-row items-center space-x-1">
                      <Text className="flex-1">
                        <Text className="text-lg pt-2">
                          {selectedOption.name}
                        </Text>
                      </Text>
                      <Text className="text-lg pt-2">
                        R{selectedOption.price}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </ScrollView>
        {/*
          {Object.entries(groupedItemsInBasket).map(([key, items]) => (
            <View
              key={key}
              className="flex-row items-center space-x-3 bg-white py-2 px-5"
            >
             
              {items[0].selectedOptions.map((option) => (
                  <View>
                    <Text className="flex-1">{option?.name}</Text>
                  </View>
                ))}
            </View>
          ))}
        */}

        <View className="p-5 bg-white  space-y-5">
          <TouchableOpacity onPress={() => setIsCollapsed(!isCollapsed)}>
            <Text>
              {" "}
              {isCollapsed
                ? "Tap to show additional info"
                : "Tap to hide additional info"}
            </Text>
          </TouchableOpacity>
          <View style={{ width: '100%', height: 1, backgroundColor: 'gray'}} />
          <Collapsible collapsed={isCollapsed}>
            <View >
              <View
                className="flex-1"
                style={{
                  alignItems:
                    timeOption.optionValue !== 0 ? "flex-start" : "center",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {timeOptions?.map((option) => (
                    <TouchableOpacity
                      key={option.optionValue}
                      onPress={() => {
                        setTimeOptionValue(option);
                      }}
                      style={{
                        backgroundColor:
                          option.optionKey === timeOption.optionKey
                            ? "bg-[#E75300]"
                            : "gray",
                        marginRight: "10px",
                      }}
                      className="rounded-lg bg-[#E75300] p-4"
                    >
                      <Text className="text-center text-white font-bold">
                        {option.optionKey}
                      </Text>
                    </TouchableOpacity>
                  ))}
                  {timeOption.optionValue !== 0 ? (
                    <View>
                      <TextField
                        type="time"
                        placeholder="Order Collect Time"
                        onChange={(e) => setTimeValueFromInput(e)}
                        inputProps={{
                          min: "9:00",
                          max: "10:00",
                        }}
                      />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              </View>

              <TextInput
                style={styles.input}
                onChangeText={(value) => {
                  setCustomerName(value);
                }}
                value={customerName}
                placeholder="Please enter your Name"
              />
              {/* <TextInput
            style={styles.input}
            onChangeText={(value) => {
              setRoomNumber(value);
            }}
            value={roomNumber}
            placeholder="Please enter your room number"
          /> */}
              <TextInput
                style={styles.input}
                editable
                multiline
                numberOfLines={5}
                maxLength={500}
                onChangeText={(value) => {
                  setOrderNotes(value);
                }}
                value={orderNotes}
                placeholder="Add a note to your order"
              />

              <View className="flex-1" style={{ alignItems: "center" }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {options?.map((option) => (
                    <TouchableOpacity
                      key={option.optionValue}
                      onPress={() => {
                        setTip(option);
                      }}
                      style={{
                        backgroundColor:
                          option.optionKey === tipOption.optionKey
                            ? "bg-[#E75300]"
                            : "gray",
                        marginRight: "10px",
                      }}
                      className="rounded-lg bg-[#E75300] p-4"
                    >
                      <Text className="text-center text-white font-bold">
                        {option.optionKey}
                      </Text>
                    </TouchableOpacity>
                  ))}
                </View>
              </View>
            </View>
          </Collapsible>

          <View className="bg-white" style={{marginTop:10}}>
            <TouchableOpacity
              className="rounded-lg bg-[#E75300] p-2 "
              onPress={() => setIsCouponCollapsed(!isCouponCollapsed)}
            >
              <Text className="text-center text-white font-bold">
                {" "}
                {isCouponCollapsed ? "Hide Coupon" : "Apply Coupon"}
              </Text>
            </TouchableOpacity>
            <Collapsible collapsed={!isCouponCollapsed}>
              {restaurantId !== "" ? (
                <CouponComponent
                  onApplyCoupon={applyCoupon}
                  serviceProviderId={restaurantId}
                />
              ) : (
                <></>
              )}
            </Collapsible>
          </View>          
 
          {/* <View className="flex-row items-center px-5 bg-white">
            <Text className="flex-auto">
              <Text className="text-md">Menu tray charge</Text>
              <Text
                className="text-md"
                style={{ float: "inline-end" }}
              >
                <Currency quantity={serviceCharge} currency="ZAR" />
              </Text>
            </Text>
          </View> */}
          <View className="flex-row items-center px-5 bg-white">
            <Text className="flex-auto">
              <Text className="text-md">VAT (15%)</Text>
              <Text
                className="text-md"
                style={{ float: "inline-end" }}
              >
                <Currency quantity={vatValue} currency="ZAR" />
              </Text>
            </Text>
          </View>
          <View className="flex-row items-center px-5 bg-white">
            <Text className="flex-auto">
              <Text className="text-md font-extrabold">Order Total</Text>
              <Text className="text-md font-extrabold"  style={{ float: "inline-end" }}>
                <Currency quantity={orderTotal} currency="ZAR" />
              </Text>
            </Text>
          </View>
          {/* onPress={() => navigation.navigate("PreparingOrderScreen")} */}
          <TouchableOpacity
            onPress={handleYocoPayment}
            className="rounded-lg bg-[#E75300] p-4"
          >
            <Text className="text-center text-white font-bold">
              Place Order
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  input: {
    width: "100%",
    padding: 10,
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    color: "black",
  },
  button: {
    backgroundColor: "#E75300",
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
  },
});

export default BasketScreen;
